// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/page-not-found.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/page-not-found.tsx");
  import.meta.hot.lastModified = "1726716723003.0098";
}
// REMIX HMR END

import { Link, useNavigation } from '@remix-run/react';
import { Routes } from '~/lib/constants/routes.constent';
import Loader from './loader';
export function PageNotFound() {
  _s();
  const navigation = useNavigation();
  return <div className="container flex items-center justify-center h-screen">
      <figure className="max-w-[660px] flex items-center flex-col justify-center gap-y-6">
        <img src="/page-not-found2.png" alt="Page Not Found" />
        <figcaption>
          <h4 className='text-center'>
            Oops! Seems like we have hit some slag. The page you're looking for has gone on a brief break, perhaps off for a quick smoko.
          </h4>
          <div className="flex items-center justify-center gap-1 py-10">
            <Link to={Routes.HOME} className={`cursor-pointer duration-150 flex items-center justify-center gap-2 uppercase p-2 border-solid text-neutral-white bg-primary-500 hover:bg-primary-600 disabled:bg-grey-50 px-6 py-2 text-sm leading-6 w-full sm:w-[unset] font-bold italic ${navigation.state === "loading" && "pointer-events-none opacity-40"}`}>Back TO HOMEPAGE</Link>
            {navigation.state === "loading" && <Loader />}
          </div>
        </figcaption>
      </figure>
    </div>;
}
_s(PageNotFound, "I2WaJhUM5KV32aS1+j3KKeVsgyA=", false, function () {
  return [useNavigation];
});
_c = PageNotFound;
var _c;
$RefreshReg$(_c, "PageNotFound");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;