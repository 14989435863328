// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, useLoaderData, useMatches, useRouteError } from '@remix-run/react';
import { useNonce } from '@shopify/hydrogen';
import { json } from '@shopify/remix-oxygen';
import nProgressStyles from 'nprogress/nprogress.css';
import { useEffect } from 'react';
import pdfAnnotationLayerStyles from 'react-pdf/dist/Page/AnnotationLayer.css';
import pdfTextLayerStyles from 'react-pdf/dist/Page/TextLayer.css';
import { displayToast } from '~/components/ui/toast';
import { Toaster } from '~/components/ui/toaster';
import { getMessageSession, messageCommitSession } from '~/lib/utils/toast-session.server';
import tailwindStyles from '~/styles/tailwind.css';
import favicon from '../public/logo_main.svg';
import { PageNotFound } from './components/ui/page-not-found';
import { useGlobalLoader } from './hooks/useGlobalLoader';
/**
 * This is important to avoid re-fetching root queries on sub-navigations
 */
export const shouldRevalidate = ({
  formMethod,
  currentUrl,
  nextUrl
}) => {
  // revalidate when a mutation is performed e.g add to cart, login...
  if (formMethod && formMethod !== 'GET') {
    return true;
  }

  // revalidate when manually revalidating via useRevalidator
  if (currentUrl.toString() === nextUrl.toString()) {
    return true;
  }
  return false;
};
export function links() {
  return [{
    rel: 'stylesheet',
    href: tailwindStyles
  }, {
    rel: 'stylesheet',
    href: nProgressStyles
  }, {
    rel: 'stylesheet',
    href: pdfAnnotationLayerStyles
  }, {
    rel: 'stylesheet',
    href: pdfTextLayerStyles
  }, {
    rel: 'preconnect',
    href: 'https://cdn.shopify.com'
  }, {
    rel: 'preconnect',
    href: 'https://shop.app'
  }, {
    rel: 'icon',
    type: 'image/svg+xml',
    href: favicon
  }];
}
export const useRootLoaderData = () => {
  _s();
  const [root] = useMatches();
  return root?.data;
};
_s(useRootLoaderData, "qWoibZqbzlhGDRlxEMM9P1NS8i8=", false, function () {
  return [useMatches];
});
export async function loader({
  request
}) {
  const messageSession = await getMessageSession(request);
  const toastMessage = messageSession.get('toastMessage');
  if (!toastMessage) {
    return json({
      toastMessage: null
    });
  }
  if (!toastMessage.type) {
    throw new Error('Message should have a type');
  }
  return json({
    toastMessage
  }, {
    headers: {
      'Set-Cookie': await messageCommitSession(messageSession)
    }
  });
}
export default function App() {
  _s2();
  const nonce = useNonce();
  const {
    toastMessage
  } = useLoaderData();
  useGlobalLoader();
  useEffect(() => {
    if (!toastMessage) {
      return;
    }
    const {
      message,
      type
    } = toastMessage;
    switch (type) {
      case 'success':
        displayToast({
          message,
          type
        });
        break;
      case 'error':
        displayToast({
          message,
          type
        });
        break;
      default:
        throw new Error(`${type} is not handled`);
    }
  }, [toastMessage]);
  return <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="relative">
        <Outlet />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <LiveReload nonce={nonce} />
        <Toaster />
      </body>
    </html>;
}
_s2(App, "WMqDIYVFXZ72Eaop6p/OngcKtsw=", false, function () {
  return [useNonce, useLoaderData, useGlobalLoader];
});
_c = App;
export function ErrorBoundary() {
  _s3();
  const error = useRouteError();
  const rootData = useRootLoaderData();
  const nonce = useNonce();
  let errorMessage = 'Unknown error';
  let errorStatus = 500;
  if (isRouteErrorResponse(error)) {
    errorMessage = error?.data?.message ?? error.data;
    errorStatus = error.status;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }
  return <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <PageNotFound />
        <Outlet />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <LiveReload nonce={nonce} />
      </body>
    </html>;
}
_s3(ErrorBoundary, "+p55Hab08DY4H5xf0N/REF/dzJc=", false, function () {
  return [useRouteError, useRootLoaderData, useNonce];
});
_c2 = ErrorBoundary;
var _c, _c2;
$RefreshReg$(_c, "App");
$RefreshReg$(_c2, "ErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;